<template>
  <div class="evadetail">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>预约管理</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goback1">预约康复</el-breadcrumb-item>
          <el-breadcrumb-item>预约详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="blueTitle" style="margin-left:12px;">预约康复</div>
      <div class="baseInfo">
          <div class="bace-tip"><span class="circle"></span><span class="circle-text">基本信息</span></div>
          <div class="infoBox" v-if="baseInfo">
              <div class="text-line">
                <div class="text-row"><span class="father-text">就诊人姓名</span><span class="value-text">{{baseInfo.name}}</span></div>
                <div class="text-row"><span class="father-text">证件类型</span><span class="value-text">{{baseInfo.cardType}}</span></div>
                <div class="text-row"><span class="father-text">手机号码</span><span class="value-text">{{baseInfo.mobile}}</span></div>
              </div>

               <div class="text-line">
                <div class="text-row"><span class="father-text">性别</span><span class="value-text">{{baseInfo.gender}}</span></div>
                <div class="text-row"><span class="father-text">证件号码</span><span class="value-text">{{baseInfo.cardId}}</span></div>
                <div class="text-row"><span class="father-text">出生年月</span><span class="value-text">{{baseInfo.birthday}}</span></div> 
              </div>

               <div class="text-line" style="margin-right:200px">
                <!-- <div class="text-row"><span class="father-text">出生年月</span><span class="value-text">{{baseInfo.birthday}}</span></div> -->
              </div>

          </div>
      </div>

      <!-- 预约信息 -->
      <div class="blueTitle" style="margin-left:12px;">预约信息</div>
          <OrderInfoTable/>
  </div>
</template>

<script>
import OrderInfoTable from '@/components/table/orderInfoTable.vue'
import {viewRecover} from '@/api/order.js'
export default {
  beforeRouteLeave(to,from,next){
        if(to.name === 'Recover'){
            to.meta.keepAlive = true
        }
        next()
    },
  components:{
    OrderInfoTable
  },
      data(){
        return{
          id:null,
          baseInfo:null,
          tableData:[{
              hospital:'',
              dept:'',
              time:'',
              status:'',
              timeRange:''
          }]
        }
      },
      created(){
      if(this.$route.query){
        this.id = this.$route.query.id;
        this.viewDetails(this.id);
        this.tableData[0].hospital = this.$route.query.hospital;
        this.tableData[0].dept = this.$route.query.deptName;
        this.tableData[0].time = this.$route.query.workTime;
        this.tableData[0].status = this.$route.query.status;
        this.tableData[0].timeRange = this.$route.query.timeRange;
       
         
      }
    },

      methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
      viewDetails(id){
        viewRecover({
          id:id
        }).then(res=>{
          if(res.data.status==200){
            //console.log("data",res.data.data);
            this.baseInfo = res.data.data;
            this.id2 = res.data.data.id
          }
        })
      }
   }
}
</script>

<style lang="stylus" scoped>
//deep
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

//component style 
.blueTitle
  font-size: 16px 
  margin-top: 36px
  color: #353535
  line-height: 28px 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:4px
    background-color: #00CCCC

//normal 
.evadetail
  overflow hidden
  .baseInfo
    width: 100%
    // height: 250px 
    margin-top: 14px
    overflow hidden
    border-radius: 8px
    background-color: #EDFEFD
    .bace-tip
      margin-top: 30px 
      margin-left: 16px 
      align-items: center
      display: flex 
      .circle
        display: block
        width: 10px 
        height: 10px 
        border-radius: 50%
        background-color: #00CCCC
      .circle-text
        font-size: 16px 
        color: #323233
        margin-left: 6px
    .infoBox
      margin-left: 32px 
      display: flex
      justify-content: space-between
      margin-top: 30px
      .text-line
          .text-row
            margin-bottom: 40px 
            .father-text
              font-size: 14px 
              color: #323233
            .value-text
              margin-left: 20px 
              font-size: 14px 
              color: #323233





</style>>