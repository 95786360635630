<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="data"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    :header-cell-style="tableHeaderCellStyle">
   
    <!-- 表头开始 -->
    <el-table-column
      prop="hospital"
      label="预约医院"
      align="center">
    </el-table-column>
    <el-table-column
      prop="dept"
      label="预约科室"
      align="center">
    </el-table-column>
    <el-table-column
      prop="time"
      label="预约时间"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="timeRange"
      label="预约时间段"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    
    <el-table-column
       prop="status"
      align="center"
      label="预约状态">
       <template slot-scope="scope">
       <span style="display: inline-block;background-color: #CEF8F8;border-radius: 15px;width: 86px;color:#00CCCC" v-if="scope.row.status=='已预约'">{{scope.row.status}}</span>
       <span style="display: inline-block;background-color: #FFC2C2;border-radius: 15px;width: 86px;color:#FC5555" v-if="scope.row.status!='已预约'">{{scope.row.status}}</span>
      </template>
    </el-table-column>

  </el-table>

</div>
</template>

<script>

  export default {
    data() {
      var data = this.$parent.tableData;
      return {
        data,
        }
    },
  
   
    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC;";
        },

    },
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}
.resoult{
  text-align: left;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>